@import 'styles/colors.scss';

@import './styles/flex';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body,
#root {
    height: 100%;
    width: 100%;
}

body {
    font-size: 14px;
    background-color: $white;
}

a {
    &:link,
    &:hover,
    &:active,
    &:visited {
        text-decoration: none;
        color: $violet;
    }
}

input,
input::placeholder {
    font-family: 'Inter';
    font-size: 14px;
}
