@import 'styles/media.scss';
@import 'styles/flex.scss';

.ButtonsPlace {
    @extend .flex-end;
    margin-top: 10px;

    position: absolute;
    right: 110px;
    top: 90px;

    @include media('<=Lphone') {
        position: relative;
        left: 0;
        top: 0;
    }

    .Button {
        margin-left: 10px;
    }
}
