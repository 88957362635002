@import 'styles/media.scss';
@import 'styles/flex.scss';

.FormButtons {
    @extend .flex;
    margin-top: 20px;

    button {
        margin-right: 10px;
    }
}
