.flex {
    display: flex;
}

.flex-between {
    @extend .flex;
    justify-content: space-between;
}

.flex-center {
    @extend .flex;
    justify-content: center;
    align-items: center;
}

.flex-center-between {
    @extend .flex;
    align-items: center;
    justify-content: space-between;
}

.flex-column {
    @extend .flex;
    flex-direction: column;
}

.flex-horizontal-aligntment {
    @extend .flex;
    align-items: center;
}

.flex-gap {
    @extend .flex;
    gap: 1rem;
}

.flex-start {
    @extend .flex;
    align-items: center;
}

.flex-end {
    @extend .flex;
    justify-content: end;
    align-items: center;
}

.flex-align-center {
    align-items: center;
}
