@import 'styles/media.scss';
@import 'styles/flex.scss';

.ContentTitle {
    @extend .flex-between;

    @include media('<=Lphone') {
        flex-direction: column;
    }

    .ButtonsPlace {
        @extend .flex-between;
        margin-top: 10px;
    }
}

.SeachButton {
    margin-left: 10px;

    @include media('<=Lphone') {
        width: 100%;
        margin-top: 15px;
    }
}

.Button {
    margin-right: 10px;
}
