@use 'styles/colors' as *;

.globalToast {
    width: 100%;
    min-height: 64px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: translateY(-100px);
    animation: slideDown 5s;

    z-index: 10000;

    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }
}

.success {
    background-color: #c3e7b8;
}

.error {
    background-color: #e7b79f;
}

.toastCloseIcon {
    width: 28px;
    height: 28px;
    fill: $lightBlack;
    cursor: pointer;
}

@keyframes slideDown {
    0%,
    100% {
        -webkit-transform: translateY(-100px);
    }
    10%,
    90% {
        -webkit-transform: translateY(0px);
    }
}
