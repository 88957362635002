@import 'styles/media.scss';

.mainContainer {
    .pageContentWrapper {
        min-height: 100%;
        width: 100%;
        overflow-y: auto;
        padding: 40px 110px;

        @include media('<=Lphone') {
            padding: 20px 30px;
        }
    }
}
