@import 'styles/colors.scss';

.contentTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;

    color: $titleColor;
}
