// Default colors
$black: #000000;
$white: #ffffff;

// Colors from design
$lightBlue: #73d1f7;
$blue: #668fe7;
$violet: #7a50ee;
$lightGray: #f4f5f7;
$lightBlack: #1a1a1a;
$red: #f53131;
$green: #00cb85;

$titleColor: #3b3b3b;
$buttonColor: #337ab7;
$boxShadowColor: #e6e6e6;
$errorColor: #b73333;
