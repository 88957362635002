@import 'styles/media.scss';
@import 'styles/flex.scss';

.FormButtons {
    @extend .flex-between;

    width: 440px;
    margin-top: 20px;

    button {
        margin-right: 10px;
    }

    .DeleteButton {
        border-color: #b73333;
        color: #b73333;
    }
}

.ModalContent {
    @extend .flex-column;

    span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: #3b3b3b;
        margin: 32px 0;
    }

    button {
        @include media('<=Lphone') {
            width: 100%;
        }
    }
}
