@import 'styles/flex';
@import 'styles/media.scss';

.HomeContainer {
    @extend .flex-center;

    @include media('<=Lphone') {
        flex-direction: column;
    }
}

.homeBox {
    padding: 0px;

    width: 273px;
    height: 270px;

    /* Inside auto layout */

    margin: 0px 48px;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(59, 59, 59, 0.05);

    @include media('<=Lphone') {
        margin-bottom: 20px;
    }

    &:hover {
        box-shadow: 0px 5px 15px rgba(59, 59, 59, 0.05);
    }

    .Top {
        @extend .flex-center;

        width: 273px;
        height: 210px;

        background: #edf7ff;
        border-radius: 4px 4px 0px 0px;
    }

    .Bottom {
        @extend .flex-center;

        width: 100%;
        height: 60px;
        background: white;
    }

    span {
        width: 174px;
        height: 32px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 32px;

        color: #337ab7;

        margin: 0px 10px;
        text-align: center;
    }
}
