@use 'styles/media' as *;
@use 'styles/colors' as *;

.modal {
    &Root {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 101;
    }

    &CenterRoot {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 101;
    }

    &CenterWrapper {
        position: relative;
        z-index: 101;
        width: 370px;
        background: #ffffff;
        box-shadow: 0px 5px 15px rgba(59, 59, 59, 0.05);
        border-radius: 2px;
        padding: 24px;
    }

    &Wrapper {
        position: relative;
        background: #fff;
        min-width: 480px;
        height: 300px;
        padding: 24px 40px;
        z-index: 101;
        overflow: auto;
        max-width: 480px;

        @include media('<=Lphone') {
            min-width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            width: 220px;
            height: 100%;
        }
    }

    &Header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &Title {
        display: flex;
        align-items: center;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        /* identical to box height, or 133% */

        /* Gray Tones/Gray-5 */

        color: #3b3b3b;

        h4:not(:only-child) {
            margin-left: 16px;
        }
    }

    &CloseIcon {
        width: 28px;
        height: 28px;
        fill: $lightBlack;
        cursor: pointer;
    }

    &Backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #fafafa99;
        z-index: 100;
    }
}
