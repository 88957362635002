@import 'styles/media.scss';
@import 'styles/flex.scss';

.procedureFormInput {
    width: 440px;
    height: 100px;
    display: flex;
    flex-direction: column;
    // margin-top: 20px; //

    @include media('<=Lphone') {
        width: 100%;
    }

    label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        /* Gray Tones/Gray-5 */

        color: #3b3b3b;
    }

    span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        /* Alert/Error-3 */

        color: #ff4d4f !important;

        margin-left: 5px;
    }

    select,
    input,
    textarea {
        margin-top: 10px;
        padding: 12px 16px;
        gap: 10px;

        /* Gray Tones/Gray-3 */

        border: 1px solid #c5c5c5;
        border-radius: 4px;
    }

    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        color: #ff4d4f;
        padding-left: 5px;
    }
}
