@import 'styles/flex';
@import 'styles/media.scss';

.ContentTitle {
    @extend .flex-between;

    @include media('<=Lphone') {
        // flex-direction: column;
        align-items: center;
    }
}

.SeachButton {
    margin-left: 10px;

    @include media('<=Lphone') {
        width: 100%;
        margin-top: 15px;
    }
}
