@import 'styles/flex';
@import 'styles/media.scss';

.contentTable {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;

    @include media('<=Lphone') {
        display: none;
    }

    th {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 24px;
        background-color: #e7e7e7;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;

        color: #3b3b3b;

        .Header {
            @extend .flex-center;

            .IconASC {
                @extend .flex-horizontal-aligntment;
            }

            .IconDESC {
                @extend .flex-horizontal-aligntment;
                transform: rotateX(180deg);
            }
        }
    }

    td {
        border-bottom: 1px solid #ddd;
        padding: 8px;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 24px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: #3b3b3b;
        text-align: center;

        max-width: 400px;
    }

    tr:hover {
        background-color: #edf7ff;
    }
}

.pagination {
    @extend .flex-center;
    margin-top: 20px;

    // @include media('<=Lphone') {
    //     display: none;
    // }

    .paginateButton {
        /* Auto layout */

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding: 4px;

        width: 32px;
        height: 32px;

        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid #c5c5c5;

        margin: 0px 4px;
        cursor: pointer;
    }

    ul {
        list-style-type: none;
        display: flex;
        margin: 0;
        padding: 0;

        li {
            @extend .paginateButton;
            border: 0;

            a {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;

                text-align: center;

                color: #808080;
            }

            .selected {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;

                text-align: center;

                color: #337ab7;
            }
        }
    }
}

.ContentTableMobile {
    display: flex;
    flex-direction: column;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    /* display: flex;
    align-items: center; */

    color: #3b3b3b;

    display: none;

    margin-top: 20px;

    @include media('<=Lphone') {
        display: flex;
    }

    .TableBox {
        margin-top: 20px;
        border: 1px solid #e7e7e7;

        .HeaderRow {
            @extend .flex-column;

            &Header {
                height: 40px;
                background: #e7e7e7;
                padding: 0 8px;
                display: flex;
                align-items: center;
            }

            &Content {
                height: 40px;
                font-weight: 700;
                padding: 0 8px;
                display: flex;
                align-items: center;
            }
        }

        .Content {
            @extend .flex-between;
            align-items: center;
            min-height: 40px;
            padding: 0 8px;

            .Header {
                font-weight: 500;

                color: #3b3b3b;
            }

            .Data {
                font-weight: 400;

                color: #808080;

                text-align: right;
            }
        }

        .Content:nth-child(even) {
            background: #fafafa;
        }
    }
}
