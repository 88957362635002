@import 'styles/media.scss';
@import 'styles/flex.scss';
@import 'styles/colors.scss';

.ContentTitle {
    @extend .flex-between;

    @include media('<=Lphone') {
        flex-direction: column;
    }

    .ButtonsPlace {
        @extend .flex-between;
        margin-top: 10px;
    }
}

.SearchTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin: 0px -2px 0px 0px;

  color: #3b3b3b;
  @include media('<=Lphone') {
    width: 100%;
    margin: 0px;
}
}

.ButtonsPlace {
  @extend .flex-end;

  @include media('<=Lphone') {
      position: relative;
      left: 0;
      top: 0;
  }

  .Button {
      margin-left: 10px;
  }
}

.SelectSearch {
    font-family: 'Inter';
}

.InputSearch {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    width: 300px;
    border: 1px solid #3b3b3b;
    border-radius: 4px;
    margin-left: 5px;
    padding: 7px;
}

