@import 'styles/media.scss';
@import 'styles/flex.scss';

.ContentTitle {
    @extend .flex-between;

    @include media('<=Lphone') {
        flex-direction: column;
    }

    .ButtonsPlace {
        @include media('<=Lphone') {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
        }
        .Button {
            margin-left: 20px;

            @include media('<=Lphone') {
                margin: 0;
            }
        }
    }
}

.procedureDetailsBox {
    padding: 12px 16px;

    /* Gray Tones/Gray-3 */

    border: 1px solid #c5c5c5;
    border-radius: 4px;
    margin-top: 20px;

    .Title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        /* identical to box height, or 133% */

        /* Primary/Blue */

        color: #337ab7;
    }

    .Row {
        @extend .flex-between;

        width: 700px;
        margin-bottom: 10px;

        @include media('<=Lphone') {
            width: 100%;
        }

        .RowTitle {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height, or 171% */

            /* Gray Tones/Gray-4 */

            color: #808080;
        }

        .RowValue {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height, or 171% */

            /* Gray Tones/Gray-5 */

            color: #3b3b3b;

            width: 400px;

            text-align: right;
        }
    }
}

.ModalContent {
    @extend .flex-column;

    span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: #3b3b3b;
        margin: 32px 0;
    }

    button {
        @include media('<=Lphone') {
            width: 100%;
        }
    }
}
