@import 'styles/colors.scss';
@import 'styles/flex';
@import 'styles/media.scss';

.searchFilterBox {
    @extend .flex-between;
    align-items: center;

    padding: 8px 8px 8px 24px;

    height: 76px;

    background: #fafafa;
    border-radius: 9px;

    margin: 32px 0px;

    @include media('<=Lphone') {
        flex-direction: column;

        height: auto;
        align-items: flex-start;
        padding: 10px 16px;
    }

    .filters {
        @extend .flex;

        @include media('<=Lphone') {
            display: block;

            height: auto;
            width: 100%;
        }

        .filterInput {
            @extend .flex-column;
            width: 170px;
            margin-right: 20px;

            min-width: 170px;

            @include media('<=Lphone') {
                width: auto;
                margin-right: 0;
            }

            span {
                width: 125px;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;

                color: #3b3b3b;

                margin: 0px 16px;

                @include media('<=Lphone') {
                    width: 100%;
                    margin: 0px;
                }
            }

            select {
                padding: 7px 8px 7px 12px;

                width: 100%;
                height: 32px;

                border: 1px solid #c5c5c5;
                box-sizing: border-box;
                border-radius: 4px;

                margin: 0px 16px;

                @include media('<=Lphone') {
                    width: 100%;
                    margin: 0px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
