@import 'styles/colors.scss';
@import 'styles/media.scss';

.button {
    padding: 12px 24px;

    min-width: 90px;
    height: 44px;

    background: $buttonColor;
    border-radius: 4px;

    border: 0;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    text-align: center;

    color: #ffffff;
    // margin-left: 10px;
    cursor: pointer;

    @include media('<=Lphone') {
        width: 152px;
        margin: 0;
        padding: 12px 20px;
    }
}

.buttonoutline {
    @extend .button;

    border: 1px solid $buttonColor;
    color: $buttonColor;
    background: $white;
}

.deleteButtonFill {
    @extend .button;

    background-color: $errorColor;
}

.deleteButtonOutline {
    @extend .buttonoutline;

    border-color: $errorColor;
    color: $errorColor;
}

.exportButton {
    @extend .button;

    width: 117px;

    color: #ffffff !important;

    @include media('<=Lphone') {
        width: 152px;
        margin: 0;
        padding: 12px 20px;
    }
}
