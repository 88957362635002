@import 'styles/media.scss';
@import 'styles/flex.scss';

.appHeader {
    @extend .flex-horizontal-aligntment;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0 2px 8px #f0f0f0;
    padding: 5px;

    .Navigation {
        @extend .flex-horizontal-aligntment;

        .Logo {
            display: inline-block;
            padding: 0 20px;

            img {
                width: 160px;

                @include media('<=Lphone') {
                    width: 100px;
                }
            }
        }

        .Links a {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-size: 14px;
            line-height: 24px;

            text-align: center;

            color: #808080;
            margin: 0 16px;

            text-decoration: none;

            :hover {
                color: #337ab7 !important;
            }

            @include media('<=Lphone') {
                display: none;
            }
        }

        .Links :hover {
            color: #337ab7 !important;
        }

        .hamburgerIcon {
            display: none;

            @include media('<=Lphone') {
                display: flex;
                justify-content: center;
                align-items: center;
                /* width: 50px; */
                cursor: pointer;
                margin-left: 27px;
            }
        }
    }

    .Actions {
        @extend .flex;
        // padding-right: 24px;

        :hover {
            svg path {
                fill: #337ab7 !important;
            }

            span {
                color: #337ab7 !important;
            }
        }

        @include media('<=Lphone') {
            display: none;
        }

        .Event {
            @extend .flex;
            cursor: pointer;

            svg path {
                fill: #808080;
            }

            span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                /* identical to box height, or 171% */

                display: flex;
                align-items: center;

                /* Gray Tones/Gray-4 */

                color: #808080;

                margin-left: 10px;
                margin-right: 26px;
            }

            :hover ~ .Dropdown {
                display: block;
            }

            .Dropdown {
                display: none;
                position: absolute;
                background-color: #fff;
                // min-width: 160px;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;
                right: 20px;
                top: 45px;
                padding: 10px 15px;

                span {
                    color: #808080 !important;
                }
            }

            .Dropdown:hover {
                display: block;
            }
        }
    }

    .Mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 32px 32px 24px;
        position: absolute;
        width: 220px;
        height: 100vh;
        left: 0px;
        top: 0px;
        background: #ffffff;
        box-shadow: 0px 2px 8px #f0f1f2;
        z-index: 9999;

        .Icon {
            svg path {
                fill: #808080;
            }
        }

        .LinksMobile {
            @extend .flex-column;

            a {
                color: #3b3b3b;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                margin: 0px 8px;
                margin-bottom: 25px;
            }
        }
    }
}
