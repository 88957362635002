@import 'styles/colors.scss';
@import 'styles/flex';
@import 'styles/media.scss';

.contentMenu {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;

        li {
            float: left;
        }

        li {
            padding: 16px;
            text-decoration: none;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;

            text-align: center;

            color: #3b3b3b;

            box-shadow: inset 0px -2px 0px $boxShadowColor;
            cursor: pointer;
        }
    }

    .active {
        box-shadow: inset 0px -2px 0px $buttonColor !important;
        font-weight: 700 !important;
        color: $buttonColor !important;
    }
}

.ContentTitle {
    @extend .flex-between;

    @include media('<=Lphone') {
        // flex-direction: column;
        align-items: center;
    }
}
