@import 'styles/flex';
@import 'styles/media.scss';

.LoginScreen {
    form {
        @extend .flex-center;
        @extend .flex-column;
        width: 500px;
        margin: 200px auto;
        padding: 30px;
        box-shadow: 0px 5px 15px rgba(59, 59, 59, 0.05);

        @include media('<=Lphone') {
            width: 100%;
            height: 100vh;
            padding: 30px;
            margin: 0;
        }

        img {
            width: 200px;
            margin-bottom: 20px;

            @include media('<=Lphone') {
                width: 80%;
            }
        }

        span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            color: #3b3b3b;
            margin-top: 20px;
        }
    }

    .FormButtons {
        width: 100%;
        margin-top: 30px;

        .SubmitButton {
            width: 100%;
        }

        .ForgotButton {
            width: 100%;
            margin-top: 20px;
        }
    }
}
